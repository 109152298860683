































import Vue from 'vue'
import Component from 'vue-class-component'
import { MessageApi } from '../api/api'
import { messageFirebaseApi } from '../api/firebaseApi'
import { MessageCreate } from '../model/message'

const messageApi: MessageApi = messageFirebaseApi

@Component({ })
export default class FeedbackCreate extends Vue {

  subject = 'Feedback zu ...'
  message = 'Ihre Nachricht ...'

  async onSubmit (): Promise<void> {
    const messageCreate: MessageCreate = {
      subject: this.subject,
      message: this.message
    }
    await messageApi.create(messageCreate)
    await this.$router.push('/')
  }
}

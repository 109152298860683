import firebase from 'firebase'
import { feedbackCollection } from '@/firebaseConfig'
import { userStore } from '@/auth/state'
import { Pagination } from '@/shared/model/pagination'
import { Message, MessageCreate } from '../model/message'
import { MessageApi } from './api'
import { MessageFire } from './messageFire'

import Timestamp = firebase.firestore.Timestamp

class MessageFirebaseApi implements MessageApi {
  async create (message: MessageCreate): Promise<string> {
    const messageFire = toMessageFire(message)
    const doc = await feedbackCollection.add(messageFire)
    return doc.id
  }

  async get (id: string): Promise<Message> {
    const doc = await feedbackCollection.doc(id).get()
    if (!doc.exists) {
      throw new Error(`Message with ID ${id} not found.`)
    }

    const messageFire = doc.data() as MessageFire
    return fromMessageFire(messageFire, id)
  }

  async getAll (): Promise<Message[]> {
    const snaps = await feedbackCollection.get()
    return snaps.docs.map(doc => fromMessageFire(doc.data() as MessageFire, doc.id))
  }

  async getFeedbackMessages (pagination: Pagination<Message>): Promise<Message[]> {
    const lastItem = pagination.last?.createdAt ?? ''
    const snaps = await feedbackCollection
      .orderBy('createdAt', 'desc')
      .startAfter(lastItem)
      .limit(pagination.pageSize)
      .get()
    return snaps.docs.map(doc => fromMessageFire(doc.data() as MessageFire, doc.id))
  }
}

function toMessageFire (messageCreate: MessageCreate): MessageFire {
  return {
    subject: messageCreate.subject,
    message: messageCreate.message,
    authorId: userStore.state.currentUser.id,
    authorEmail: userStore.state.currentUser.email,
    authorUserGroupId: userStore.state.currentUser.group,
    createdAt: Timestamp.now()
  }
}

function fromMessageFire (messageFire: MessageFire, id: string): Message {
  return {
    id: id,
    subject: messageFire.subject,
    message: messageFire.message,
    authorId: messageFire.authorId,
    authorEmail: messageFire.authorEmail,
    authorUserGroupId: messageFire.authorUserGroupId,
    createdAt: messageFire.createdAt.toDate()
  }
}

const messageFirebaseApi = new MessageFirebaseApi()
export { messageFirebaseApi }

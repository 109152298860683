



















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Message } from '../model/message'
import { messageFirebaseApi } from '../api/firebaseApi'
import { MessageApi } from '../api/api'

const messageApi: MessageApi = messageFirebaseApi

@Component({ })
export default class FeedbackRead extends Vue {

  feedbacks: Message[] = []
  pageSize = 10

  async created (): Promise<void> {
    this.feedbacks = await messageApi.getFeedbackMessages({
      pageSize: this.pageSize
    })
  }

  async loadMore (): Promise<void> {
    const moreFeedbacks = await messageApi.getFeedbackMessages({
      last: this.feedbacks[this.feedbacks.length - 1],
      pageSize: this.pageSize
    })
    this.feedbacks.push(...moreFeedbacks)
  }
}
